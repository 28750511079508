import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Typography, Box, Button, Hidden } from '@mui/material';
import { FiChevronsDown } from 'react-icons/fi';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';
import Article from '../components/Article';

const MikraToSmoMiPage = ({ data }) => {
  const { strapiMikraToSmoMi } = data;
  const images = withArtDirection(getImage(strapiMikraToSmoMi.naslovnaSlika.localFile), [
    { media: '(max-width: 992px)', image: getImage(strapiMikraToSmoMi.mobilnaSlika.localFile) },
  ]);

  const handleClick = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 836,
        left: 836,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout>
      <CenteredContainer>
        <HeroWrapper>
          <Box maxWidth={[280, 280, 620, 360]} mt="auto" mb={[8, 10, 14, 'auto']}>
            <Typography variant="h1" color="primary" gutterBottom>
              Mikra to smo mi!
            </Typography>
            <Hidden xlDown>
              <Typography variant="subtitle1" gutterBottom>
                {strapiMikraToSmoMi.podnaslov}
              </Typography>
            </Hidden>
            <Button endIcon={<FiChevronsDown />} onClick={handleClick}>
              Idi na priče klijenata
            </Button>
          </Box>
        </HeroWrapper>
        <Box id="mikra-stories" width="100%" mb={[4, 14]} mt={[4, 0]}>
          <Typography variant="overline" align="left" color="primary">
            {strapiMikraToSmoMi.drugiPodnaslov}
          </Typography>
          <Typography variant="h2" align="left" color="textSecondary">
            {strapiMikraToSmoMi.drugiNaslov}
          </Typography>
        </Box>
        {strapiMikraToSmoMi.klijentPrice.map((story) => (
          <Article data={story} />
        ))}
      </CenteredContainer>
      <ImageWrapper ml={[0, 4]} mr={[0, 5]} mt={[8, 10, 11, '18vh']}>
        <GatsbyImage className="art-directed" image={images} alt="" />
      </ImageWrapper>
    </Layout>
  );
};

const ImageWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (max-width: 992px) {
    .art-directed {
      height: 55vh;
      img {
        object-position: 0 100%;
      }
    }
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => `
  height: calc(100vh - ${theme.topBarHeight}px);
  ${theme.breakpoints.down('xl')} {
    height: calc(100vh - ${theme.mobileTopBarHeight}px);
  }
  `}
`;

export const query = graphql`
  query MikraToSmoMiQuery {
    strapiMikraToSmoMi {
      naslov
      podnaslov
      drugiNaslov
      drugiPodnaslov
      naslovnaSlika {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, quality: 90, layout: FULL_WIDTH)
          }
        }
      }
      mobilnaSlika {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      klijentPrice {
        id
        align
        naslov
        tekst
        prica {
          url
        }
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 540, height: 448, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

export default MikraToSmoMiPage;
